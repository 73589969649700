<template>
  <div>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row"></div>
      <div class="content-body">
        <form @submit.prevent="saveFinale()">

        <section class="invoice-preview-wrapper">
          <div class="row invoice-preview">
            <!-- Invoice -->
            <div class="col-xl-9 col-md-8 col-12">
              <div class="card invoice-preview-card">
                <div class="card-body invoice-padding pb-0">
                  <!-- Header starts -->
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                  >
                    <div>
                      <div class="logo-wrapper">
                        <img
                          class="img-fluid brand-text"
                          src="/assets/logo.png"
                          alt="Login V2"
                          style="width: 100px; margin-top: -30px"
                        />
                      </div>
                      <p class="card-text mb-25">Société:<b>Next-Consult</b></p>
                      <p class="card-text mb-25">
                        Delegué:<b>{{ commande.user_id["name"] }}</b>
                      </p>

                      <p class="card-text mb-0">
                        Téléphone:<b>{{ commande.user_id["telephone"] }}</b>
                      </p>
                    </div>
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-title">
                        Commande
                        <span class="invoice-number"
                          >#{{ commande.numero }}</span
                        >
                      </h4>

                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Creation :</p>
                        <p class="invoice-date">
                          {{ commande.created_at | formatDate }}
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Commande :</p>
                        <p class="invoice-date">
                          {{ commande.date_commande | formatDate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Header ends -->
                </div>
                <hr class="invoice-spacing" />

                <!-- Address and Contact starts -->
                <div class="card-body invoice-padding pt-0">
                  <div class="row invoice-spacing">
                    <div class="col-xl-8 p-0">
                      <h6 class="mb-2">Commande pour:</h6>
                      <h6 class="mb-25">{{ commande.client_id["name"] }}</h6>
                      <p class="card-text mb-25">
                        {{ commande.client_id["categorie"] }}
                      </p>
                      <p class="card-text mb-25">
                        {{ commande.client_id["adresse"] }}
                      </p>
                      <p class="card-text mb-25">
                        {{ commande.client_id["phone"] }}
                      </p>
                      <p class="card-text mb-0">
                        {{ commande.client_id["email"] }}
                      </p>
                    </div>
                    <div class="col-xl-4 p-0 mt-xl-0 mt-2">
                      <h6 class="mb-2">Payement Details:</h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">Méthode de payment:</td>
                            <td>
                              <span class="font-weight-bold"
                                ><b>Virement</b></span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">Total a payé:</td>
                            <td>
                              <span class="font-weight-bold"
                                ><b>{{ total }} dt</b></span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- Address and Contact ends -->

                <!-- Invoice Description starts -->

                <div v-if="neww == 0">
                  <button
                    class="btn btn-info float-right"
                    id="additem"
                    style="margin-top: 10px; margin-bottom: 10px"
                    @click.prevent="newrow()"
                    :disabled="neww == 1"
                    v-if="commande.etat_commande == 'en attente'"
                  >
                    Ajouter un article
                  </button>
                </div>
                <div style="margin-top: 30px">
                  <div class="row" v-if="neww === 1">
                    <br />
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="first"
                          >Choisir l'article
                          <span class="obligatoire">*</span>
                        </label>

                        <v-select
                          :multiple="false"
                          v-model="produit_selected"
                          :closeOnClick="false"
                          :options="all_produits"
                          :reduce="(all_produits) => all_produits"
                          label="reference"
                        />
                      </div>
                    </div>
                    <div class="col-md-3" v-if="produit_selected">
                      <div class="form-group">
                        <label for="first"
                          >Quantités disponible<span class="obligatoire"
                            >*</span
                          >
                        </label>

                        <span
                          v-if="produit_selected.quantites_disponible > 0"
                          class="form-control bg-success text-light"
                        >
                          {{ produit_selected.quantites_disponible }}
                        </span>
                        <span
                          class="form-control bg-danger text-light"
                          v-if="produit_selected.quantites_disponible == 0"
                        >
                          {{ produit_selected.quantites_disponible }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-3" v-if="produit_selected">
                      <div class="form-group">
                        <label for="first"
                          >Quantités<span class="obligatoire">*</span>
                        </label>

                        <input
                          type="number"
                          class="form-control"
                          id="quantite_total"
                          v-model="quantite_total"
                          required
                           :min="1"
                          style="height: 32px"
                        />
                      </div>
                    </div>
                    <div class="col-md-1">
                      <br />
                      <div class="form-group" style="margin-top: 5px">
                        <button
                          class="btn btn-success float-right"
                          @click.prevent="validRow()"
                          :disabled="
                            produit_selected.length === 0  || quantite_total<1
                          "
                        >
                          <i class="mdi mdi-shield-check"> </i>
                        </button>
                      </div>
                    </div>

                    <div class="col-md-1">
                      <br />
                      <div class="form-group" style="margin-top: 5px">
                        <button
                          class="btn btn-danger float-right"
                          @click.prevent="annuler()"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <form @submit.prevent="addcommande()"></form>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="py-1">Photo</th>
                        <th class="py-1">Réference Produit</th>
                        <th class="py-1">Prix</th>
                        <th class="py-1">Quantités demandé</th>
                        <th class="py-1">Quantités disponible</th>
                        <th
                          class="py-1"
                          v-if="commande.etat_commande == 'en attente'"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(produit, index) in commande.produits"
                        :key="index"
                      >
                        <td class="py-1">
                          <img
                            v-if="produit.produit_id.photo != null"
                            width="100px"
                            variant="light-primary"
                            :src="
                              require('@/images/' + produit.produit_id.photo)
                            "
                            class="badge-minimal"
                          />

                          <img
                            v-if="produit.produit_id.photo == null"
                            width="100px"
                            variant="light-primary"
                            :src="require('@/images/product.png')"
                            class="badge-minimal"
                          />
                        </td>
                        <td class="py-1">
                          <span class="font-weight-bold">{{
                            produit.produit_id.reference
                          }}</span>
                        </td>
                        <td class="py-1">
                          <span class="font-weight-bold"
                            >{{ produit.produit_id.prix }} dt</span
                          >
                        </td>
                        <td class="py-1">
                          <input
                            class="form-control"
                            type="number"
                            :min="1"
                            :disabled="commande.etat_commande != 'en attente'"
                            placeholder="Entrer la nouvelle quantité"
                            v-model="produit.quantites"
                            required
                          />
                        </td>

                        <td class="py-1">
                          <span class="font-weight-bold"
                            >{{ produit.produit_id.quantites_disponible }}
                          </span>
                        </td>

                        <td
                          class="py-1"
                          v-if="commande.etat_commande == 'en attente'"
                        >
                          <button
                            class="btn btn-danger"
                            @click.prevent="deleteItem(produit.produit_id._id)"
                            v-if="commande.produits.length > 1"
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <hr class="invoice-spacing" />

                <!-- Invoice Note starts -->

                <!-- Invoice Note ends -->
              </div>
            </div>
            <!-- /Invoice -->

            <!-- Invoice Actions -->
            <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
              <div class="card">
                <div class="card-body">
                  <router-link to="/commandes">
                    <span class="btn btn-warning btn-block mb-75">
                      Retour
                    </span>
                  </router-link>
                  <a
                    class="btn btn-outline-secondary btn-block mb-75"
                    target="_blank"
                  >
                    Imprimer la commande
                  </a>

                  <button
                    class="btn btn-success btn-block"
                    data-toggle="modal"
                    data-target="#add-payment-sidebar"
                   
                    v-if="commande.etat_commande == 'en attente'"
                  >
                    Enregistrer la commande
                  </button>
                </div>
              </div>
            </div>
            <!-- /Invoice Actions -->
          </div>
        </section>
      </form>

        <!-- /Send Invoice Sidebar -->

        <!-- Add Payment Sidebar -->

        <!-- /Add Payment Sidebar -->
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/axios";
import Toast from "sweetalert2";

export default {
  name: "app-header",
  data() {
    return {
      commande: {},
      total: 0,
      all_produits: [],
      neww: 0,
      produit_selected: "",
      quantite_total: "",
      stocks: [],
      stock_quantite: "",
    };
  },

  created() {
    this.getcommande();
  },
  computed: {
    totel() {},
  },

  methods: {
    deleteItem(id) {
      this.commande.produits = this.commande.produits.filter(
        (element) => element.produit_id._id != id
      );
      this.total = 0;

      this.commande.produits.forEach((produit) => {
        this.total += parseInt(produit.produit_id.prix);
      });
    },

    validRow() {
      if (this.all_produits.length != 0) {
        let newobject = {
          produit_id: this.produit_selected,
          quantites: this.quantite_total,
        };
        this.commande.produits.push(newobject);
        this.total = 0;

        this.commande.produits.forEach((produit) => {
          this.total += parseInt(produit.produit_id.prix);
        });
        this.annuler();
      }
    },
    annuler() {
      this.produit_selected = "";
      this.quantite_total = "";
      this.neww = 0;
    },
    newrow() {
      HTTP.get("produits/getproduits").then(
        (response) => {
          this.all_produits = response.data;

          this.commande.produits.forEach((produit_echant) => {
            this.all_produits = this.all_produits.filter(
              (element) => element._id != produit_echant.produit_id._id
            );
          });
          if (this.all_produits.length == 0) {
            Toast.fire({
              position: "top-center",
              icon: "error",
              title: "Stock non valide",
            });
          } else {
            this.neww = 1;
          }
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },

    getcommande() {
      this.total = 0;
      var object = {};
      var quantites = [];
      HTTP.get("commandes/getonecommande/" + this.$route.params.id)
        .then((response) => {
          this.commande = response.data;
          this.commande.produits.forEach((produit) => {
            this.total += parseInt(produit.produit_id.prix);
          });
        })
        .then((err) => console.log(err));
    },

    saveFinale() {
      HTTP.put("commandes/commandeupdate/" + this.$route.params.id, this.commande).then(
        (res) => {
          console.log(res.data.type);
          if (res.data.type == "valide_change") {
            Toast.fire({
              icon: "success",
              title: "Commande enregistré",
            });

            this.getcommande();
          }
        },
        (err) => {}
      );
    },
  },
};
</script>
